import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BULK_UPLOAD_API } from '../apiEndpoints';

// Action to update the upload progress
export const updateUploadProgress = (progress) => ({
  type: 'bulkUploadModal/updateUploadProgress',
  payload: progress,
});

export const postBulkUploadData = createAsyncThunk(
  'bulkUploadModal/postData',
  async (modalDataObj, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: 'post',
        url: BULK_UPLOAD_API,
        data: modalDataObj,
        headers: { 'Content-Type': 'application/json' },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(updateUploadProgress(progress));
        },
      });
      // dispatch(openModal({ message: 'Applied successfully!' }));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const bulkModalSlice = createSlice({
  name: 'bulkUploadModal',
  initialState: {
    isOpen: false,
    jobId: null,
    postBulkUploadDataStatus: 'idle',
    postBulkUploadDataError: null,
    postBulkUploadData: {},
    uploadProgress: 0,
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.jobId = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    updateUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postBulkUploadData.pending, (state) => {
        state.postBulkUploadDataStatus = 'loading';
      })
      .addCase(postBulkUploadData.fulfilled, (state, action) => {
        state.postBulkUploadDataStatus = 'succeeded';
        state.postBulkUploadData = action.payload;
        state.postBulkUploadDataError = null;
        state.uploadProgress = 0;
      })
      .addCase(postBulkUploadData.rejected, (state, action) => {
        console.log('action: ', action);
        state.postBulkUploadDataStatus = 'failed';
        state.postBulkUploadDataError = action.payload.status;
        state.uploadProgress = 0;
      });
  },
});

export const { openModal, closeModal } = bulkModalSlice.actions;

export default bulkModalSlice.reducer;
