import React, { useState } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import styles from './ThreeDots.module.css';
import { iconThreeDots } from '../../assets';

const DropdownMenu = ({ menuItems = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };
  const ref = useClickAway(() => {
    setIsOpen(false);
  });
  return (
    <div className={styles.dropdownContainer} ref={ref}>
      <img
        className={styles.dropdownIcon}
        src={iconThreeDots}
        alt='Dropdown Icon'
        onClick={toggleDropdown}
      />
      {isOpen && menuItems.length > 0 && (
        <div className={styles.dropdownMenu}>
          {menuItems.map((item, index) => (
            <p
              key={index}
              style={item.color ? { color: item.color } : {}}
              className={styles.dropdownItem}
              onClick={() => {
                setIsOpen(false);
                item.onClick();
              }}>
              {item.text}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
