import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, Link } from 'react-router-dom';
import { get } from 'lodash';
import ThreeDots from '../../components/ThreeDots/ThreeDots';
import { fetchJobsData, updateStatus } from '../../redux/jobsSlice';
import { hideToast, showToast } from '../../redux/toastSlice';
import fullUrl from '../../utils/fullUrl';
import {
  iconSearch,
  iconPositive,
  iconDownCaret,
  iconFilter,
} from '../../assets';
import styles from './Jobs.module.css';

function unixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}`;
}

const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, hasMore, teams } = useSelector((state) => state.jobs);

  const [selectedOptions, setSelectedOptions] = useState({
    team: '',
    postedOn: '6Months',
    status: 'active',
  });

  const handleSelectChange = (e) => {
    setSelectedOptions({
      ...selectedOptions,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    const filteredOptions = Object.entries(selectedOptions).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    dispatch(fetchJobsData(filteredOptions));
  };

  useEffect(() => {
    dispatch(fetchJobsData({ postedOn: '6Months', status: 'active' }));
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(
      fetchJobsData({
        ...selectedOptions,
        offset: data?.length || 20,
        replaceExisting: false,
      })
    );
  };

  return (
    <section className={styles.jobsMainContent}>
      <header className={styles.jobsHeader}>
        <div className={styles.jobsTitleWrap}>
          <h1 className={styles.jobsTitle}>Jobs</h1>
          <span className={styles.jobsTrialWrap}>
            <span className={styles.jobsTrialDays}>
              {/* {get(data, 'trial_left', 0)} days */}
              {get({}, 'trial_left', 0)} days
            </span>{' '}
            of trial left
          </span>
        </div>
        <div className={styles.jobsHeaderBox}>
          <div style={{ position: 'relative', marginRight: '1.6rem' }}>
            <img
              src={iconSearch}
              alt='search'
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
              }}
            />
            {/* <img src={iconSearch} alt='search' /> */}
            <input
              className={styles.jobsSearchWrap}
              placeholder='Search for applicants and jobs'
              style={{ paddingLeft: '40px', textAlign: 'left' }}
            />
          </div>
          <button
            className={styles.jobsJobCta}
            onClick={() => {
              navigate('/client/create-job');
            }}>
            <img src={iconPositive} alt='positive' />
            <span className={styles.jobsJobText}>Create a job</span>
          </button>
        </div>
      </header>

      <div className={styles.jobsFiltersBox}>
        <div className={styles.jobsFilter}>
          <span className={styles.jobsFilterTitle}>status</span>
          <div className={styles.jobsSelectContainer}>
            <select
              className={styles.jobsStyledSelect}
              name='status'
              value={selectedOptions.status}
              onChange={handleSelectChange}>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>

        <div className={styles.jobsFilter}>
          <span className={styles.jobsFilterTitle}>teams</span>
          <div className={styles.jobsSelectContainer}>
            <select
              className={styles.jobsStyledSelect}
              name='team'
              value={selectedOptions.team}
              onChange={handleSelectChange}>
              <option defaultValue='' value={''}>
                All teams
              </option>
              {data &&
                teams &&
                teams.length > 0 &&
                teams.map((team) => (
                  <option key={team} value={team}>
                    {team}
                  </option>
                ))}
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>

        <div className={styles.jobsFilter}>
          <span className={styles.jobsFilterTitle}>Posted on</span>
          <div className={styles.jobsSelectContainer}>
            <select
              className={styles.jobsStyledSelect}
              name='postedOn'
              value={selectedOptions.postedOn}
              onChange={handleSelectChange}>
              <option value='1Day'>Last 1 day</option>
              <option value='1Week'>Last 1 week</option>
              <option value='1Month'>Last 1 month</option>
              <option value='3Months'>Last 3 months</option>
              <option value='6Months'>Last 6 months</option>
              <option value='1Year'>Last 1 Year</option>
              <option value='allTime'>All time</option>
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>
        <button className={styles.jobsFilterCTA} onClick={handleFilter}>
          <img src={iconFilter} alt='filter' />
          <span className={styles.jobsFilterText}>Add filters</span>
        </button>
      </div>

      <InfiniteScroll
        dataLength={data?.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>You have seen all jobs</b>
          </p>
        }
        className={styles.jobsSection}>
        {data?.map(
          ({
            id,
            job_title,
            team,
            applicants,
            posted_by,
            posted_on,
            status,
            job_url,
          }) => {
            return (
              <div
                className={styles.jobsRow}
                key={id || `${posted_by}_${job_title}_${posted_on}`}>
                <div className={styles.jobsRowInner}>
                  <div className={styles.jobsTitleRow}>
                    <div className={styles.jobsTitleHeaderWrap}>
                      <Link to={`/client/job-detail/${id}`}>{job_title}</Link>
                    </div>
                    <div className={styles.jobsStatus}>
                      {/* <div>
                        <div>Hiring completed: 50% </div>
                        <div className={styles.jobsProgressbar}></div>
                      </div> */}
                      <ThreeDots
                        menuItems={[
                          {
                            text: 'Copy Embed Link',
                            onClick: () => {
                              const embedLink = fullUrl(job_url);
                              navigator.clipboard
                                .writeText(embedLink)
                                .then(() => {
                                  dispatch(
                                    showToast({
                                      message: 'Link copied!',
                                      type: 'success',
                                    })
                                  );
                                })
                                .catch((err) => {
                                  console.error('Could not copy text: ', err);
                                });
                            },
                          },
                          ...(status
                            ? [
                                {
                                  text: 'Close Job',
                                  onClick: () => {
                                    dispatch(updateStatus({ jobId: id }));
                                  },
                                  color: '#FF5757',
                                },
                              ]
                            : []),
                        ]}
                      />
                    </div>
                  </div>
                  <div className={styles.jobsLine}></div>
                  <div className={styles.jobDetails}>
                    <div className={styles.jobsDetailCol}>
                      <span className={styles.jobsLabel}>team</span>
                      <span className={styles.jobsDetailsValue}>
                        {team || 'N/A'}
                      </span>
                    </div>
                    <div className={styles.jobsDetailCol}>
                      {applicants > 0 ? (
                        <a
                          href={`/client/applicants/${id}`}
                          className={styles.clickableLink}>
                          <div className={styles.jobsDetailCol}>
                            <span className={styles.jobsLabel}>applicants</span>
                            <span className={styles.jobsDetailsValue}>
                              {applicants}
                            </span>
                          </div>
                        </a>
                      ) : (
                        <div className={styles.jobsDetailCol}>
                          <span className={styles.jobsLabel}>applicants</span>
                          <span className={styles.jobsDetailsValue}>
                            {applicants}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className={styles.jobsDetailCol}>
                      <span className={styles.jobsLabel}>Posted by</span>
                      <span className={styles.jobsDetailsValue}>
                        {posted_by}
                      </span>
                    </div>
                    <div className={styles.jobsDetailCol}>
                      <span className={styles.jobsLabel}>Posted on</span>
                      <span className={styles.jobsDetailsValue}>
                        {unixTimestampToDate(posted_on)}
                      </span>
                    </div>

                    <div className={styles.jobsDetailCol}>
                      <span className={styles.jobsLabel}>Status</span>
                      <span
                        className={
                          styles.jobsDetailsValue +
                          ' ' +
                          styles.jobsDetailsStatusValue
                        }>
                        {status ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </InfiniteScroll>
    </section>
  );
};

export default Jobs;
