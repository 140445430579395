import React from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import styles from './DropdownBtn.module.css';

const DropdownBtn = ({ menuItems }) => {
  const ref = useClickAway(() => {
    // console.log('sindeio');
    // setShouldOpenDropdownBtn(false);
  });
  return (
    <div className={styles.dropdownContainer} ref={ref}>
      <div className={styles.dropdownMenu}>
        {menuItems.map((item, index) => (
          <p
            key={index}
            style={item.color ? { color: item.color } : {}}
            className={styles.dropdownItem}
            onClick={() => {
              item.onClick();
            }}>
            {item.text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default DropdownBtn;
