import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { APPLICANTS_API, MOVE_APPLICANTS } from '../apiEndpoints';

export const moveApplicants = createAsyncThunk(
  'applicants/moveApplicants',
  async ({ applicantIds, moveTo }, { rejectWithValue, dispatch }) => {
    try {
      await axios({
        method: 'post',
        url: `${MOVE_APPLICANTS}`,
        data: {
          applicant_ids: applicantIds,
          bucket: moveTo,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      return '13';
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log('err.response: ', err.response);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchApplicantsData = createAsyncThunk(
  'applicants/fetchData',
  async (queryParams, { getState }) => {
    const { offset = 0, replaceExisting = true, ...restParams } = queryParams;
    const response = await axios.get(APPLICANTS_API, {
      params: { ...queryParams, offset },
    });

    // const response = {
    //   data: {
    //     company_name: 'True Hire',
    //     data: [
    //       {
    //         applicants: 0,
    //         id: 110,
    //         applicant_title: '',
    //         applicant_url: 'http://localhost:5003/applicants/__NjTRqxbp',
    //         posted_by: 'demo@truehire.work',
    //         posted_on: 1721147289,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 109,
    //         applicant_title: '',
    //         applicant_url: 'http://localhost:5003/applicants/__2PU2a5Ps',
    //         posted_by: 'demo@truehire.work',
    //         posted_on: 1721145933,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 108,
    //         applicant_title: '',
    //         applicant_url: 'http://localhost:5003/applicants/__vvyf9V0O',
    //         posted_by: 'demo@truehire.work',
    //         posted_on: 1720437465,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 104,
    //         applicant_title: '28',
    //         applicant_url: 'http://localhost:5003/applicants/_28_joDZynx2',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512834,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 103,
    //         applicant_title: '27',
    //         applicant_url: 'http://localhost:5003/applicants/_27_9sKo50TV',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512830,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 102,
    //         applicant_title: '26',
    //         applicant_url: 'http://localhost:5003/applicants/_26_kq0fIUSe',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512826,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 101,
    //         applicant_title: '25',
    //         applicant_url: 'http://localhost:5003/applicants/_25_3FKl30ID',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512820,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 99,
    //         applicant_title: '23',
    //         applicant_url: 'http://localhost:5003/applicants/_23_QmeQMFk3',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512810,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 98,
    //         applicant_title: '22',
    //         applicant_url: 'http://localhost:5003/applicants/_22_AluZYy8q',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512806,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 97,
    //         applicant_title: '21',
    //         applicant_url: 'http://localhost:5003/applicants/_21_qoD8fszn',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512803,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 96,
    //         applicant_title: '20',
    //         applicant_url: 'http://localhost:5003/applicants/_20_aDMSAg4V',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512799,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 95,
    //         applicant_title: '19',
    //         applicant_url: 'http://localhost:5003/applicants/_19_VZvFHpev',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512794,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 94,
    //         applicant_title: '18',
    //         applicant_url: 'http://localhost:5003/applicants/_18_LdcunOio',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512791,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 93,
    //         applicant_title: '17',
    //         applicant_url: 'http://localhost:5003/applicants/_17_DYnX3att',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512787,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 92,
    //         applicant_title: '16',
    //         applicant_url: 'http://localhost:5003/applicants/_16_QhkCCv54',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512783,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 91,
    //         applicant_title: '15',
    //         applicant_url: 'http://localhost:5003/applicants/_15_YvF61DiK',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512779,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 90,
    //         applicant_title: '14',
    //         applicant_url: 'http://localhost:5003/applicants/_14_oas1fZx9',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512775,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 89,
    //         applicant_title: '13',
    //         applicant_url: 'http://localhost:5003/applicants/_13_DTQJDeV9',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512771,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 88,
    //         applicant_title: '12',
    //         applicant_url: 'http://localhost:5003/applicants/_12_d2kRuxzt',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512767,
    //         status: true,
    //         team: '',
    //       },
    //       {
    //         applicants: 0,
    //         id: 87,
    //         applicant_title: '11',
    //         applicant_url: 'http://localhost:5003/applicants/_11_Yv0nhnJo',
    //         posted_by: 'amrit@truehire.work',
    //         posted_on: 1719512764,
    //         status: true,
    //         team: '',
    //       },
    //     ],
    //     teams: [
    //       'Backup and Disaster Recovery - Google Cloud',
    //       'Marketing',
    //       'Human Resources (HR)',
    //       'Finance',
    //     ],
    //     trial_left: 33,
    //   },
    // };

    return {
      data: response.data.data,
      hasMore: response.data.data.length === 20,
      offset: offset + response.data.data.length,
      teams: response.data.teams,
      restAll: {
        applied: response.data.total_applied_count,
        interviewed: response.data.total_interviewed_count,
        joined: response.data.total_joined_count,
        recommended: response.data.total_recommended_count,
        role_offered: response.data.total_role_offered_count,
        shortlisted: response.data.total_shortlisted_count,
      },
      replaceExisting,
    };
  }
);

export const applicantsSlice = createSlice({
  name: 'applicants',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    offset: 0,
    hasMore: false,
    restAll: {},
  },
  reducers: {
    saveDataApplicants: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplicantsData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchApplicantsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.replaceExisting) {
          state.data = action.payload.data;
        } else {
          state.data = [...state.data, ...action.payload.data];
        }
        state.hasMore = action.payload.hasMore;
        state.offset = action.payload.offset;
        state.teams = action.payload.teams;
        state.restAll = action.payload.restAll;
      })
      .addCase(fetchApplicantsData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(moveApplicants.fulfilled, (state, action) => {
        // const currentData = current(state.data);
        state.moveApplicantsSuccess = true;
        state.moveApplicantsError = null;
        // state.data = currentData.filter((obj) => obj.id !== action.payload);
      })
      .addCase(moveApplicants.rejected, (state, action) => {
        state.moveApplicantsSuccess = false;
        state.moveApplicantsError = action.error.message;
      });
  },
});
export const { saveDataApplicants } = applicantsSlice.actions;
export default applicantsSlice.reducer;
