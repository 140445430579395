import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './pages/Dashboard/Dashboard';
import Jobs from './pages/Jobs/Jobs';
import CreateJob from './pages/CreateJob/CreateJob';
import { openModal, closeModal } from './redux/modalSlice';
import Modal from './components/Modal/Modal';
import SideNav from './components/SideNav/SideNav';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import TnC from './pages/TnC/TnC';
import JobDetails from './pages/JobDetails/JobDetails';
import ApplyJob from './pages/ApplyJob/ApplyJob';
import ToastContainer from './components/Toast/ToastContainer';
import BulkUpload from './components/BulkUpload/BulkUpload';
import Applicants from './pages/Appilcants/Applicants';

function MainContent() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modal);
  const location = useLocation();
  const hasRootBackground =
    location.pathname.includes('/signup/') ||
    location.pathname.includes('/login/') ||
    location.pathname.includes('/tnc') ||
    location.pathname.includes('/apply/');

  // useHotkeys('ctrl+q', () => {
  //   if (isOpen) {
  //     dispatch(closeModal());
  //   } else {
  //     dispatch(openModal());
  //   }
  // });

  return (
    <div className={hasRootBackground ? '' : 'rootBackground'}>
      {!hasRootBackground && <SideNav />}
      <Modal />
      <ToastContainer />
      <BulkUpload />
      <Routes>
        <Route path='/client/dashboard' element={<Dashboard />} />
        <Route path='/client/jobs' element={<Jobs />} />
        <Route
          path='/client/applicants/:applicantId?'
          element={<Applicants />}
        />
        <Route path='/client/create-job' element={<CreateJob />} />
        <Route path='/client/job-detail/:jobId' element={<JobDetails />} />
        <Route path='/client/signup/:action' element={<Signup />} />
        <Route path='/client/login/:action/:token?' element={<Login />} />
        <Route path='/client/apply/:jobId' element={<ApplyJob />} />
        <Route path='/client/tnc' element={<TnC />} />
        <Route path='/client/*' element={<div>404</div>} />
      </Routes>
    </div>
  );
}
function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

export default App;
